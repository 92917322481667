import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../modules/shared/shared.module';
import { UtilityService } from '../../services';
import { FormGroup, } from '@angular/forms';

@Component({
  selector: 'app-tutor-education',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './tutor-education.component.html',
  styleUrls: ['./tutor-education.component.scss']
})
export class TutorEducationComponent implements OnInit {
  currentEducationList: EducationItemModel[] = [];
  highestEducationList: EducationItemModel[] = [];
  suggestedTutorLevel: string | null = null;

  @Output() onConfirm = new EventEmitter();

  @Input() educationForm?: FormGroup;
  @Input() isLandingPage: boolean = false;

  @ViewChild('bottomContainer') bottomContainer: ElementRef;

  constructor(
    private utilityService: UtilityService,
  ) { }

  ngOnInit() {
    this.getEducationList();
  }

  onBothControlsHaveValues() {
    if (this.educationForm.get('highestEducation')?.value && this.educationForm.get('currentEducation')?.value) {
      this.suggestTutorLevel();
    }
  }

  confirm() {
    if (this.educationForm.valid) {
      this.onConfirm.emit();
    }
  }

  scrollToBottom() {
    if (!this.isLandingPage) {
      this.bottomContainer.nativeElement.scrollIntoView({ behavior: 'smooth' });
    } else {
      return
    };
  }

  suggestTutorLevel(): void {
    this.suggestedTutorLevel = null;
    const highestEducation = this.educationForm.get('highestEducation')?.value;
    const currentEducation = this.educationForm.get('currentEducation')?.value;
    if (highestEducation && currentEducation) {
      const combinedEducationIndex = highestEducation + currentEducation;

      if (this.utilityService.isNL) {
        switch (true) {
          case (combinedEducationIndex >= 10 && combinedEducationIndex <= 29):
            this.suggestedTutorLevel = 'texts.TutorRegistrationSection_suggested_tutor_option_one';
            break;
          case (combinedEducationIndex === 33 || combinedEducationIndex === 34 || combinedEducationIndex === 44):
            this.suggestedTutorLevel = 'texts.TutorRegistrationSection_suggested_tutor_option_two';
            break;
          case (combinedEducationIndex === 35 || (combinedEducationIndex >= 43 && combinedEducationIndex <= 45)):
            this.suggestedTutorLevel = 'texts.TutorRegistrationSection_suggested_tutor_option_three';
            break;
          case (combinedEducationIndex === 51):
            this.suggestedTutorLevel = 'texts.TutorRegistrationSection_suggested_tutor_option_four';
            break;
          default:
            this.suggestedTutorLevel = 'texts.TutorRegistrationSection_suggested_tutor_option_five';
            break;
        }
      } else {
        switch (true) {
          case (combinedEducationIndex === 13 || combinedEducationIndex === 23 || combinedEducationIndex === 27 || combinedEducationIndex === 73):
            this.suggestedTutorLevel = 'texts.TutorRegistrationSection_suggested_tutor_option_one';
            break;
          case ((combinedEducationIndex >= 14 && combinedEducationIndex <= 16) || (combinedEducationIndex >= 24 && combinedEducationIndex <= 26) || (combinedEducationIndex >= 33 && combinedEducationIndex <= 35) || combinedEducationIndex === 37):
            this.suggestedTutorLevel = 'texts.TutorRegistrationSection_suggested_tutor_option_two';
            break;
          case (combinedEducationIndex === 36 || combinedEducationIndex === 77):
            this.suggestedTutorLevel = 'texts.TutorRegistrationSection_suggested_tutor_option_three';
            break;
          case (combinedEducationIndex === 21):
            this.suggestedTutorLevel = 'texts.TutorRegistrationSection_suggested_tutor_option_four';
            break;
          case ((combinedEducationIndex >= 43 && combinedEducationIndex <= 47) || combinedEducationIndex === 56 || combinedEducationIndex === 64 || combinedEducationIndex === 66 || combinedEducationIndex === 67):
            this.suggestedTutorLevel = 'texts.TutorRegistrationSection_suggested_tutor_option_five';
            break;
          default:
            this.suggestedTutorLevel = 'texts.TutorRegistrationSection_suggested_tutor_option_six';
            break;
        }
      }
    }
  }

  getEducationList() {
    this.highestEducationList = [
      { value: 10, text: 'texts.TutorRegistrationSection_highest_education_option_one' },
      { value: 20, text: 'texts.TutorRegistrationSection_highest_education_option_two' },
      { value: 30, text: 'texts.TutorRegistrationSection_highest_education_option_three' },
      { value: 40, text: 'texts.TutorRegistrationSection_highest_education_option_four' },
      { value: 50, text: 'texts.TutorRegistrationSection_highest_education_option_five' }
    ];

    this.currentEducationList = [
      { value: 1, text: 'texts.TutorRegistrationSection_current_education_option_one' },
      { value: 2, text: 'texts.TutorRegistrationSection_current_education_option_two' },
      { value: 3, text: 'texts.TutorRegistrationSection_current_education_option_three' },
      { value: 4, text: 'texts.TutorRegistrationSection_current_education_option_four' },
      { value: 5, text: 'texts.TutorRegistrationSection_current_education_option_five' }
    ];

    if (this.utilityService.isDE) {
      this.highestEducationList.push(
        { value: 60, text: 'texts.TutorRegistrationSection_highest_education_option_six' },
        { value: 70, text: 'texts.TutorRegistrationSection_highest_education_option_seven' }
      );

      this.currentEducationList.push(
        { value: 6, text: 'texts.TutorRegistrationSection_highest_education_option_six' },
        { value: 7, text: 'texts.TutorRegistrationSection_highest_education_option_seven' }
      );
    }
  }
}

export interface EducationItemModel {
  value: number;
  text: string;
}