<app-login-or-register-frame #mainComponent>
    <div class="dialog-content">
        <div class="first-step">
            <div class="dialog-title" translate>texts.Student_login_dialog_title</div>
            <form [formGroup]="submitForm" (ngSubmit)="onSubmit()">
                <div class="input-title" translate>texts.Misc_field_email</div>
                <input class="input-field" [placeholder]="'texts.Misc_field_email' | translate" required=""
                    formControlName="email">
                <app-input-required-error *ngIf="showError"
                    [inputControl]="submitForm.controls.email"></app-input-required-error>
                <div class="input-title" translate>texts.Misc_field_password</div>
                <div class="password">
                    <input class="input-field" matInput [type]="hide ? 'password' : 'text'"
                        [placeholder]="'texts.Misc_field_password' | translate" required="" formControlName="password">
                    <button class="password-toggle" (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hide">
                        <i *ngIf="!hide" class="fas fa-eye-slash"></i>
                        <i *ngIf="hide" class="fas fa-eye"></i>
                    </button>
                </div>
                <app-input-required-error *ngIf="showError"
                    [@errorMessage]="submitForm.controls.password.dirty && !submitForm.controls.password.valid"
                    [inputControl]="submitForm.controls.password"></app-input-required-error>
                <div class="recaptcha-input">
                    <re-captcha #recaptchaComponent (resolved)="reCaptchaResolved($event)" *ngIf="!isDevMode"
                        (error)="reCaptchaError($event)" [siteKey]="siteKey" errorMode="handled" size="invisible"
                        formControlName="captcha">
                    </re-captcha>
                    <div *ngIf="showRecaptchaMessage" class="error-message">
                        <div class="arrow-up"></div>
                        <div>{{'texts.LoginModalComponent_recaptcha_message' | translate}}</div>
                    </div>
                </div>
                <div *ngIf="isCredentialsError" class="error-message" [@errorMessage]="isCredentialsError">
                    {{'texts.Modal_log_email_password_error' | translate}}
                </div>
                <div class="or-text forgot-password" (click)="dialogRef.close()"><a
                        [routerLink]="['Modal_password_forgot'] | localize"
                        translate>texts.Student_login_forgot_password</a></div>
                <button class="next-button button"
                    translate>texts.New_login_or_register_dialog_login_button_text</button>
            </form>
            <div class="separator">
                <div class="or-text decorated-text" translate>texts.Misc_Or</div>
            </div>
            <div class="button google-button" translate (click)="googleLogin()">texts.Misc_login_with_google<img
                    class="google-logo" src="https://media.bijlesaanhuis.nl/logos/google-reviews-logo.png"></div>
            <div class="bottom-info" translate>texts.Misc_dont_have_account</div>
            <div class="bottom-info bottom-links"><a (click)="openRegister()" translate>texts.Misc_create_account</a>
                <span translate>texts.Misc_Or</span> <a (click)="redirectAndClose()"
                    translate>texts.Misc_register_as_tutor</a>
            </div>
        </div>
    </div>
</app-login-or-register-frame>