<header id="navigation" [class.sticky]="allowHideHeader" [ngClass]="{'hidden': utilityService.isHeaderHidden}"
    [class.package-status-navbar-shown]="utilityService.isBrowser && authService.isStudent() &&
        ((packagesFlowService.pendingPackage && packagesFlowService.pendingPackage.paymentIntent.currentInstallment != '1') || packagesFlowService.suspendedPackage)">
    <div class="header-top-row" *ngIf="!this.hideForChat">
        <a class="phone-link" *ngIf="variableService.contactVariable$ | async as contactVariables" target="new"
            [href]="'tel:' + (variableService.contactVariable$ | async)?.contactPhoneLinked">
            <i class="fa fa-phone-alt"></i>&nbsp;
            {{contactVariables.contactPhoneDisplayed}}
        </a>

        <div class="top-navigation">
            <a *ngIf="authService.user && authService.isStudent()" [routerLink]="['/Misc_Pricing'] | localize">
                <span translate>texts.HeaderSection_prices</span></a>
            <a *ngIf="authService.isTeacher() && !showTutorGuideButton"
                [routerLink]="['/Misc_docentengids'] | localize">
                <span translate>texts.Navigation_Guidelines</span>
            </a> <a *ngIf="!authService.user" [routerLink]="['/Misc_become_tutor'] | localize">
                <span translate>texts.NavigationSection_become_a_tutor</span></a>
            <a *ngIf="authService.isTeacher()" [routerLink]="['/Navigation_tutor_bonus'] | localize">
                {{'texts.NavigationSection_tutor_bonus' | translate }}</a>
            <a *ngIf="!authService.user || !authService.isTeacher()" [routerLink]="['/Navigation_faq'] | localize">
                {{'texts.NavigationSection_faq' | translate }}</a>
            <a [routerLink]="['/Misc_about_us'] | localize">
                {{'texts.NavigationSection_team' | translate }}</a>
            <a [routerLink]="['/Misc_contact'] | localize">
                {{'texts.NavigationSection_contact' | translate }}</a>
        </div>
    </div>

    <div class="main-navigation">
        <div class="logo" (click)="closeDropdown()"><a id="navbar-logo" [routerLink]="['/']" role='link'
                class="navbar-logo">
                <img [src]="'images.Misc_logo_rebrand.originalSource' | translate"
                    [alt]="'images.Misc_logo_rebrand.title' | translate">
            </a></div>
        <div class="navbar-right-section">
            <a class="menu-item" [routerLink]="['/Misc_tutor_search'] | localize"
                *ngIf="!authService.user && utilityService.isDesktopRes"
                [queryParams]="searchService.searchFilterQueryParams" data-cy="FindTutor">
                {{'texts.NavigationSection_find_tutor' | translate }}</a>
            <a class="menu-item" [routerLink]="['/Misc_Pricing'] | localize"
                *ngIf="!authService.user && utilityService.isDesktopRes">
                {{'texts.NavigationSection_prices' | translate }}</a>
            <div *ngIf="utilityService.isBrowser && !utilityService.isMobileRes && (authService.isTeacher() && !showTutorGuideButton) && authService.userDetails?.tutorDetails"
                class="acceptsNewStudentsContainer menu-item">
                <app-slide-toggle [(model)]="authService.userDetails?.tutorDetails._acceptsNewStudents"
                    (modelChange)="updatePreference()"></app-slide-toggle>
                <p class="toggle-label">
                    {{'texts.NavigationSection_Tutor_Accepts_New_Students'|
                    translate }}
                </p>
            </div>
            <div class="tutor-guide-button" *ngIf="authService.isTeacher() && showTutorGuideButton"><button
                    class="button" [routerLink]="['/Misc_docentengids'] | localize">
                    <span translate>texts.Navigation_Guidelines</span>
                </button></div>
            <div class="button login-button"
                *ngIf="utilityService.isBrowser && !authService.user && !utilityService.isMobileRes"
                (click)="openLogin();">
                <span translate>texts.NavigationSection_log_in</span>
            </div>
            <div *ngIf="!authService.user" class="register-button-vwo button" (click)="openRegister()">
                <span>
                    {{ (isBecomeTutorPage ? 'texts.NavigationSection_sign_up_as_tutor' :
                    'texts.NavigationSection_sign_up') | translate}}
                </span><i *ngIf="!utilityService.isMobileRes" class="far fa-user"></i>
            </div>
            <div class="credit-count-header" *ngIf="utilityService.isBrowser && authService.isStudent() 
					&& packagesFlowService.totalCredits && packagesFlowService.totalCredits !== '0' 
                    && (!packagesFlowService.pendingPackage || packagesFlowService.pendingPackage.paymentIntent.currentInstallment == '1' )
                    && !packagesFlowService.suspendedPackage" (click)="openPaymentVideo()">
                {{ packagesFlowService.totalCredits | creditFormatter }}
                {{'texts.NavigationSection_credits_available' | translate }}<span class="info-icon"><app-svg-icon
                        name="info-circle-green"></app-svg-icon></span>
            </div>
            <div *ngIf="authService.isStudent() && !utilityService.isMobileRes && this.appointment"
                class="online-classroom">
                <div class="lesson-details">
                    <div class="tutor-details">
                        <span *ngIf="this.appointmentType === 'Introduction'"
                            translate>texts.Misc_Upcoming_Appointment_Intro</span>
                        <span *ngIf="this.appointmentType === 'Lesson' && this.appointment.online"
                            translate>texts.Misc_Upcoming_Appointment_Online</span>
                        <span *ngIf="(this.appointmentType === 'Lesson') && !this.appointment.online"
                            translate>texts.Misc_Upcoming_Appointment_Home</span>
                        &nbsp;<b>{{appointmentTutor}}</b>
                    </div>
                    <div class="lesson-time">{{appointment.begin | date: 'dd-MM-yyyy HH:mm'}}</div>
                </div>
                <div *ngIf="this.appointment.online" class="classroom-button">
                    <div class="button classroom-button" (click)="joinClassroom()" translate>texts.Misc_Classroom
                    </div>
                </div>
            </div>
            <div *ngIf="utilityService.isBrowser && authService.isStudent() 
            && !aBTestService.studentCases?.testCase1 
            && (!packagesFlowService.totalCredits || packagesFlowService.totalCredits === '0')">
                <a [routerLink]="['/Misc_tutor_search'] | localize" class="button navbar-button"
                    [queryParams]="searchService.searchFilterQueryParams" data-cy="FindTutor">
                    {{'texts.NavigationSection_find_tutor' | translate }}</a>
            </div>
            <div *ngIf="utilityService.isBrowser && authService.isStudent() && aBTestService.studentCases?.testCase1 
                && (!packagesFlowService.totalCredits || packagesFlowService.totalCredits === '0')
                && !(packagesFlowService.pendingPackage && packagesFlowService.pendingPackage.paymentIntent.currentInstallment != '1')
                && !packagesFlowService.suspendedPackage"
                class="button navbar-button navigation-link--buy-package-desktop" (click)="openBuyPackageDialog()">
                {{'texts.NavigationSection_buy_a_package' | translate }}
            </div>
            <div *ngIf="utilityService.isBrowser && authService.isStudent() && 
                ((packagesFlowService.pendingPackage && packagesFlowService.pendingPackage.paymentIntent.currentInstallment != '1') || 
                packagesFlowService.enrollingPackageButCreditEnded ||
                packagesFlowService.suspendedPackage)" class="package-status-navbar-container">
                <app-package-status></app-package-status>
            </div>
            <div class="picture-and-dropdown" *ngIf="authService.user || !utilityService.isDesktopRes"
                (mouseover)="utilityService.isDesktopRes && showDropdown()"
                (mouseleave)="utilityService.isDesktopRes && hideDropdown($event)"
                (click)="!utilityService.isDesktopRes && toggleOnOff(); $event.stopPropagation()">
                <div class="hamburger-icon"><app-svg-icon name="hamburger-menu"></app-svg-icon></div>
                <div *ngIf="!authService.user" class="user-photo">
                    <div class="user-avatar-loggedout" [ngClass]="{'navigation-avatar--admin': authService.isAdmin()}"
                        data-cy="NavigationAvatar">
                        <app-svg-icon name="user-circle-white"></app-svg-icon>
                    </div>
                </div>
                <div *ngIf="authService.user" class="user-photo">
                    <div *ngIf="authService.getUser().image" class="navigation-avatar" data-cy="NavigationAvatar">
                        <img [lazyLoad]="(isDevMode ? 'https://staging.bijlesaanhuis.nl' : '') + '/profileImages/' + (authService.getUser().image)"
                            [alt]="authService.getUser().initials" />
                    </div>
                    <div *ngIf="!authService.getUser().image" class="user-initials"
                        [ngClass]="{'navigation-avatar--admin': authService.isAdmin()}" data-cy="NavigationAvatar">
                        <p>{{authService.getUser().initials}}</p>
                    </div>
                </div>
                <div class="dropdown-menu" *ngIf="isOpen" (mouseleave)="hideDropdown($event)"
                    (mouseenter)="keepDropdownOpen()" (click)="closeDropdown(); $event.stopPropagation()">
                    <div class="dropdown-top-part">
                        <div *ngIf="!authService.user" class="user-photo">
                            <div class="user-avatar-loggedout"
                                [ngClass]="{'navigation-avatar--admin': authService.isAdmin()}"
                                data-cy="NavigationAvatar">
                                <app-svg-icon name="user-circle-white"></app-svg-icon>
                            </div>
                        </div>
                        <div *ngIf="authService.user && utilityService.isMobileRes" class="user-photo">
                            <div *ngIf="authService.getUser().image" class="navigation-avatar"
                                data-cy="NavigationAvatar">
                                <img [lazyLoad]="(isDevMode ? 'https://staging.bijlesaanhuis.nl' : '') + '/profileImages/' + (authService.getUser().image)"
                                    [alt]="authService.getUser().initials" />
                            </div>
                            <div *ngIf="!authService.getUser().image" class="user-initials"
                                [ngClass]="{'navigation-avatar--admin': authService.isAdmin()}"
                                data-cy="NavigationAvatar">
                                <p>{{authService.getUser().initials}}</p>
                            </div>
                        </div>
                        <div *ngIf="utilityService.isMobileRes" class="close-button"
                            (click)="toggleOnOff(); $event.stopPropagation()">
                            <app-svg-icon name="close-dropdown"></app-svg-icon>
                        </div>
                    </div>

                    <div *ngIf="!authService.isTeacher()!">
                        <div>
                            <a [routerLink]="['/Misc_tutor_search'] | localize" class="navigation-link"
                                [queryParams]="searchService.searchFilterQueryParams" data-cy="FindTutor">
                                {{'texts.NavigationSection_find_tutor' | translate }}</a>
                        </div>
                        <div>
                            <a [routerLink]="['/Misc_Pricing'] | localize" *ngIf="!utilityService.isDesktopRes"
                                class="navigation-link">
                                {{'texts.NavigationSection_prices' | translate }}</a>
                        </div>
                        <div *ngIf="authService.isStudent() && !utilityService.isDesktopRes">
                            <a [routerLink]="['/Misc_contact'] | localize" class="navigation-link">
                                {{'texts.Misc_contact' | translate }}
                            </a>
                        </div>
                        <div class="navigation-separator"></div>
                    </div>

                    <div *ngIf="!authService.user">
                        <div>
                            <a *ngIf="!authService.user" class="navigation-link"
                                [routerLink]="['/Misc_become_tutor'] | localize">
                                <span translate>texts.NavigationSection_become_a_tutor</span></a>
                        </div>
                        <div>
                            <a [routerLink]="['/Misc_about_us'] | localize" class="navigation-link">
                                {{'texts.NavigationSection_team' | translate }}</a>
                        </div>
                        <div>
                            <a [routerLink]="['/Misc_contact'] | localize" class="navigation-link">
                                {{'texts.Misc_contact' | translate }}
                            </a>
                        </div>
                        <div class="navigation-separator"></div>
                        <div *ngIf="utilityService.isMobileRes"
                            class="navigation-link PopupBtn navigation-link--inloggen" (click)="openLogin();">
                            {{'texts.NavigationSection_log_in' | translate }}
                        </div>
                    </div>

                    <div *ngIf="authService.isTeacher()">
                        <div>
                            <a data-cy="Dashboard" class="navigation-link"
                                [routerLink]="['/Misc_dashboard/Misc_Dashboard_Tutor'] | localize">
                                {{'texts.Dashboard' | translate }}
                            </a>
                        </div>
                        <div>
                            <a [routerLink]="['/Misc_overview'] | localize" class="navigation-link">
                                {{'texts.NavigationSection_overview' | translate }}
                            </a>
                        </div>
                        <div>
                            <a [routerLink]="['/Navigation_messages'] | localize" class="navigation-link">
                                {{'texts.NavigationSection_messages' | translate }}
                                <span *ngIf="chatService.notifications.messages_unseen" class="navigation-badge"
                                    id="navigation-badge__messages">
                                    {{chatService.notifications.messages_unseen}}
                                </span>
                            </a>
                        </div>
                        <div>
                            <a data-cy="EditProfileTutor" class="navigation-link"
                                [routerLink]="['/Misc_tutor_edit_profile'] | localize">
                                {{'texts.NavigationSection_edit_profile' | translate }}
                            </a>
                        </div>
                        <div class="navigation-separator"></div>
                        <div *ngIf="!utilityService.isDesktopRes">
                            <div>
                                <a class="navigation-link" *ngIf="!showTutorGuideButton"
                                    [routerLink]="['/Misc_docentengids'] | localize">
                                    <span translate>texts.Navigation_Guidelines</span>
                                </a>
                            </div>
                            <div>
                                <a [routerLink]="['/Navigation_tutor_bonus'] | localize" class="navigation-link">
                                    {{'texts.NavigationSection_tutor_bonus' | translate }}</a>
                            </div>
                            <div>
                                <a [routerLink]="['/Misc_contact'] | localize" class="navigation-link">
                                    {{'texts.Misc_contact' | translate }}
                                </a>
                            </div>
                            <div class="navigation-separator"></div>
                        </div>
                    </div>

                    <div *ngIf="authService.isStudent()">
                        <div>
                            <a data-cy="Dashboard" class="navigation-link"
                                [routerLink]="['/Misc_dashboard/Misc_Dashboard_Student'] | localize">
                                {{'texts.Dashboard' | translate }}
                            </a>
                        </div>
                        <div>
                            <a data-cy="ViewAppointments" class="navigation-link"
                                [routerLink]="['/Misc_overview'] | localize">
                                {{'texts.Misc_overview_appointments_packages' | translate }}
                            </a>
                        </div>
                        <div>
                            <a [routerLink]="['/Navigation_messages'] | localize" class="navigation-link">
                                {{'texts.NavigationSection_messages' | translate }}
                                <span *ngIf="chatService.notifications.messages_unseen" class="navigation-badge"
                                    id="navigation-badge__messages">
                                    {{chatService.notifications.messages_unseen}}
                                </span>
                            </a>
                        </div>
                        <div>
                            <a data-cy="EditProfileStudent" class="navigation-link"
                                (click)="editOrCompleteStudentProfile()">
                                {{'texts.NavigationSection_edit_profile' | translate }}
                            </a>
                        </div>
                        <div class="navigation-separator"></div>
                        <div>
                            <div class="credit-count-dropdown" *ngIf="!utilityService.isDesktopRes && this
                            .appointment
                            && packagesFlowService.totalCredits && packagesFlowService.totalCredits !== '0'"
                                (click)="openPaymentVideo()">
                                {{ packagesFlowService.totalCredits | creditFormatter }}
                                {{'texts.NavigationSection_credits_available' | translate }}<span
                                    class="info-icon"><app-svg-icon name="info-circle-green"></app-svg-icon></span>
                            </div>
                        </div>
                        <div
                            *ngIf="(packagesFlowService.totalCredits && packagesFlowService.totalCredits !== '0') && aBTestService.studentCases?.testCase1 ">
                            <div class="button package-button-mobile" (click)="openBuyPackageDialog()">
                                {{'texts.NavigationSection_buy_a_package' | translate }}
                            </div>
                        </div>
                        <div class="navigation-separator"
                            *ngIf="(packagesFlowService.totalCredits && packagesFlowService.totalCredits !== '0') && (authService.userDetails?.state === 'Tutoring' || authService.userDetails?.state === 'Introducing')">
                        </div>
                    </div>

                    <div class="navigation-link" *ngIf="authService.user" role="list-item">
                        <a id="navbar-logout-button" (click)="logOut()">
                            {{'texts.NavigationSection_log_out' | translate }}
                        </a>
                    </div>
                </div>
                <div *ngIf="!utilityService.isDesktopRes" class="overlay" [hidden]="!isOpen"></div>
            </div>
        </div>
    </div>
</header>

<div *ngIf="utilityService.isMobileRes && toggleReady && (!this.hideForChat && utilityService.isBrowser && authService.isTeacher() && authService.userDetails?.tutorDetails && authService.userDetails?.tutorDetails)"
    class="acceptsNewStudentsContainer">
    <app-slide-toggle [(model)]="authService.userDetails?.tutorDetails._acceptsNewStudents"
        (modelChange)="updatePreference()"></app-slide-toggle>
    <p class="toggle-label">
        {{'texts.NavigationSection_Tutor_Accepts_New_Students'|
        translate }}
    </p>
</div>

<div *ngIf="authService.isStudent() && this.appointment && utilityService.isMobileRes"
    class="online-classroom-mobile  ">
    <div class="lesson-details">
        <div class="tutor-details">
            <span *ngIf="this.appointmentType === 'Introduction'" translate>texts.Misc_Upcoming_Appointment_Intro</span>
            <span *ngIf="this.appointmentType === 'Lesson' && this.appointment.online"
                translate>texts.Misc_Upcoming_Appointment_Online</span>
            <span *ngIf="(this.appointmentType === 'Lesson') && !this.appointment.online"
                translate>texts.Misc_Upcoming_Appointment_Home</span>
            &nbsp;<b>{{appointmentTutor}}</b>
        </div>
        <div class="lesson-time">{{appointment.begin | date: 'dd-MM-yyyy HH:mm'}}</div>
    </div>
    <div *ngIf="this.appointment.online" class="classroom-button">
        <div class="button classroom-button" (click)="joinClassroom()" translate>texts.Misc_Classroom</div>
    </div>
</div>