import { StudentTutorRelationStatus } from "app/constants";
import { UserObj } from "app/microservice-clients/user";
import { Moment } from "moment";
import { AppointmentUserInformation } from "./appointment.model";

export interface BookingResponseModel {
  id: string;
  begin: string;
  end: string;
  type: string;
  status: string;
  amountStudentUnit: string;
  amountStudent: number;
  amountTutor: number;
  tutorLevel: number;
  course: number;
  duration: number;
  tutorLevelName: string;
  createdAt: string;
  updatedAt: string;
  firstBooking: boolean;
  appointmentCount: number;
  student: {
    id: string;
    email: string;
    surname: string;
    forename: string;
  };
  tutor: {
    id: string;
    email: string;
    surname: string;
    forename: string;
    profileImage: string;
  };
}

export class BookingParametersModel {
  studentID: string;
  tutorID: string;
  online: boolean;
  home: boolean;
  courses: any[];
  isCredits?: boolean;
  bookingPeriodStart: string;
  bookingPeriodEnd: string;
}

export class BookingModel {
  id?: string;
  online: boolean;
  intro?: boolean;
  date: Moment;
  time: string;
  duration: string;
  courseID: string;
}

export class BookingDataModel {
  id?: number;
  student: string;
  tutor: string;
  online: boolean;
  intro?: boolean;
  begin: string;
  end: string;
  course?: number;
  type?: string;
}
export class EditDataModel {
  id?: string;
  student: AppointmentUserInformation;
  tutor: AppointmentUserInformation;
  online: boolean;
  intro?: boolean;
  begin: string;
  end: string;
  course?: number;
  type?: string;
}

export class RecurrentBookingDataModel {
  appointment: BookingDataModel;
  startDate: string;
  endDate: string;
  daysOfWeek: Array<string>;
  skipConflicts: boolean;
}

export interface DataForBookingModel {
  tutorData: TutorDataForBookingModel,
  studentData: StudentDataForBookingModel,
}

export interface StudentDataForBookingModel {
  id: string,
  name: string,
  coordinates: {
    latitude: number,
    longitude: number
  }
}

export interface StudentDataForSearchCtaModel {
  id: string,
  name: string
}

export interface TutorDataForBookingModel {
  details: UserObj,
  tutorPrices: any,
  relationStatus: StudentTutorRelationStatus
}

export interface TutorDataForSearchCtaModel {
  details: UserObj,
  relationStatus: StudentTutorRelationStatus
}

export class TutorTimesWithDurationsModel {
  begin: string;
  durations: Array<string>;
  recommended: string;
}

export enum AfterLoginAction {
  intro = 'intro',
  message = 'message'
}

export class EditDateAndHourModel {
  selectedDate: Moment;
  selectedHoursAndMinutes: string;
  duration: string;
}